const showaStartYear = 1926;
const heiseiStartYear = 1989;
const reiwaStartYear = 2019;

export const japaneseYearText = (year: number): string => {
  if (year >= reiwaStartYear) {
    return `令和${year - reiwaStartYear + 1}`;
  }
  if (year >= heiseiStartYear) {
    return `平成${year - heiseiStartYear + 1}`;
  }
  return `昭和${year - showaStartYear + 1}`;
};
